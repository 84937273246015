import React from 'react';
import './App.css';
import HomePage from './pages/Home';
import 'antd/dist/reset.css';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
