import { Col, Row } from "antd";
import React from "react";
import People from './images/People.png';
import './style.scss';

const WorkWithUs = () => {
  return (
    <div className="workwithus">
      <Row className="workwithus-container">
        <Col xl={12} lg={12} md={12} xs={24} className="workwithus-content">
          <h2 className="text-white workwithus-text">People who Work with Us.</h2>
          <div className="full-image workwithus-absolute" >
            <img src={People} alt="People who work with us" className="workwithus-image" />
          </div>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24} className="padding-left">
          <ul className="workwithus-list">
            <li><span className="text-bold">100+</span> Organizations get PDPA Compliance Solution</li>
            <li><span className="text-bold">10</span> FinTechs get License and Advice on their day-to-day operations</li>
            <li><span className="text-bold">20+</span> Innovative Products get Delivered Legally </li>
            <li><span className="text-bold">10</span> Startup gets their legal burden off with our CLO services</li>
          </ul>
        </Col>
        <div className="full-image workwithus-absolute-mobile" >
          <img src={People} alt="People who work with us" className="workwithus-image" />
        </div>
      </Row>
     
    </div>
  )
}

export default WorkWithUs;