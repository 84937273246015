import React from "react";
import './style.scss';

const FirstSection = () => {
  return (
    <div className="first-section" id="home">
     <div>
        <h1 className="text-maroon first-section-title">LawXTech</h1>
        <h2 className="text-mauve">Your legal Buddy</h2>
        <div className="text-content text-mauve">
          <p>All-in-One Legal Support Buddy for your business </p>
          <p>Just focus on your business and we will make it complex & legally enforceable</p>
        </div>
        <h4 className="text-maroon text-bold">Understanding / Fast / Affordable / risk-Free / On-Demand</h4>
     </div>
    </div>
  )
}

export default FirstSection;