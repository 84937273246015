import { Col, Row, Form, Input, Button } from "antd";
import React from "react";
import Logo from './images/logo.png';
import PatternImg from './images/pattern-contact.png';
import './style.scss';

const ContactUs = () => {
  const { TextArea } = Input;

  return (
    <div className="contactus" id="contact">
      <div className="contactus-wrapper">
        <Row>
          <Col xl={12} lg={12} xs={24}>
            <div className="contactus-content">
              <div className="contactus-detail">
                <img src={Logo} alt="LawXTech Logo" />
                <h3 className="text-white margin-top">Your legal Buddy</h3>
                <Row className="contactus-address text-left">
                  <Col xl={12} lg={12} xs={24} className="contactus-padding">
                    <p>OUR ADDRESS</p>
                    <p className="text-small">บริษัท ลอว์เอ็กซ์เทค จํากัด
                    7 ซอย จันทน์ 43 แยก 9
                    ถนนจันทน์ แขวงทุ่งวัดดอน
                    เขตสาทร กรุงเทพมหานคร</p>
                  </Col>
                  <Col xl={12} lg={12} xs={24} className="contactus-padding">
                    <p>OUR CONTACTS</p>
                    <p className="text-small">rubkwan@lawxtech.io
                    +669.1884.0466
                    linktr.ee/rubkwan</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} xs={24}>
            <div className="contactus-form-wrapper">
                <Row>
                  <Col xl={4}> </Col>
                  <Col xl={20}><h3 className="text-maroon text-bold">We here to help.</h3></Col>
                </Row>
                <Form 
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 20,
                  }}>
                  <div>{}
                    <Form.Item
                      label="Name"
                      name="name"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label="Phone"
                      name="phone"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label="Email"
                      name="email"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item 
                      label="Message"
                      name="message"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </div>
                  <Row>
                    <Col xl={4}> </Col>
                    <Col xl={20}> <Button className="contactus-btn" type="primary" shape="round">Send message</Button></Col>
                  </Row>
                </Form>
            </div>
            <div className="pattern-contact">
              <img src={PatternImg} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

 export default ContactUs;