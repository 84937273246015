import React from "react";
import { Col, Row } from 'antd';
import AboutUsImg from './images/aboutus.png';
import './style.scss';

const AboutUs = () => {
  return (
    <div className="aboutus" id="about">
      <div className="aboutus-container">
        <Row>
          <Col xl={10} lg={10} md={10} xs={24} className="aboutus-img"><img src={AboutUsImg} alt="About Us" /></Col>
          <Col xl={14} lg={14} md={14} xs={24} className="aboutus-content">
            <h2 className="text-maroon text-bold">About Us</h2>
            <p className="text-maroon">Business’ Legal Buddy with Easy to Access / Understand and Afford Legal Solution. </p>
            <p className="text-maroon">We aspire to be the Understanding Legal Buddy for any Innovative & Traditional Business who deliver and serve the fast. Affordable. Legal risk-free and in demand solution through various technology tools, including supervised chatbot, bot automation and project management and project management tools while maintaining our professional standards.</p>
            <p className="text-maroon text-bold">Lawyers who want to Disrupt the Legal World <br/> With the right tools, we believe Legal Services can be delivered more efficiently.</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AboutUs;