import { Carousel, Card } from "antd";
import React from "react";
import Chatbot from './images/1_chatBot.png';
import ContractLifeCycle from './images/2_contract Lifecycle.png';
import MultiParty from './images/3_Multi-Party.png';
import LivingWill from './images/4_Living Will.png';

import './style.scss';

const contentStyle = {
  margin: 0,
  height: '400px',
  lineHeight: '160px',
  textAlign: 'center',
  background: "$364d79"
};

const contentData = [
  {
    title: "Legal chatbot & Basic Healthcheck",
    content: "All-in-One solution for your contract management generate with limited legal knowledge, electronically signed and manage through dashboard and notifications.",
    image: Chatbot
  },
  {
    title: "Contract Lifecycle Management",
    content: "All-in-One solution for your contract management generate with limited legal knowledge, electronically signed and manage through dashboard and notifications.",
    image: ContractLifeCycle
  },
  {
    title: "Multi-Party Dispute Resolution Platform",
    content: "Ease off the burden of a lawsuit or claim that involve many people by increasing transparency and increase the collective voice to twin",
    image: MultiParty
  },
  {
    title: "Living Will Application",
    content: "Legalize your wills and wishlist, financially and emotionally so you can leave with grace.",
    image: LivingWill
  }
]
const Solution = () => {
  const settings = {
    slidesToShow: 3,
    draggable: true,
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 1
      }
    }]
  }
  return (
    <div className="solution" id="solution">
      <div className="solution-container">
        <h2 className="text-maroon">Our LegalTech Solution</h2>
        <p className="text-mauve text-small"><span className="text-bold">‘LegalTech’</span> is the technology solution crafted and designed to help in delivering the legal 
        <br/> services more efficiently in the scalable speed.</p>
        <Carousel {...settings}>
        {contentData?.map((item) => {
          return (
            <div>
              <Card className="card-content">
                <h4 className="text-title">{item.title}</h4>
                <p className="text-small solution-content">{item.content}</p>
                <div className="full-image">
                  <img src={item.image} alt={item.title} />
                </div>
              </Card>
            </div>
          )
        })}
        </Carousel>
      </div>
    </div>
  )
}

export default Solution;