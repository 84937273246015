import React from "react";
import { Col, Row } from 'antd';
import Pattern from './images/pattern.svg';
import Founder from './images/founder.png';
import './style.scss';

const MeetFounder = () => {
  return (
    <div className="meetfounder">
      <div className="meetfounder-wrapper">
        <Row className="meetfounder-wrapper">
          <Col xl={14} lg={14} md={14} xs={[24]} className="meetfounder-content">
            <h2 className="text-white text-bold">Meet the founder</h2>
            <p className="text-white"><span className="text-bold">Rubkwan Choldumrongkul</span> is a Tai qualified lawyer with more than 15 years experiences working in various legal practice areas in the big corporate, legal startups and international organization, in the CLMV region, in particular Thailand and Lao PDR. </p>
            <p className="text-white">Her legal expertise ranged from cross-border corporate matters, personal dat protection, innovative and technology laws. Currently, Rubkwan founded LawXTech as the first digital law firm in Thailand, using bot technology to drive and deliver the scalable and affordable legal services to innovative start-ups in Thailand. She is also the Vice President of the Thai Start-up Association.</p>
          </Col>
          <Col xl={10} lg={10} md={10} xs={24} className="meetfounder-img">
            <img src={Pattern} alt="background pattern" className="pattern" />
            <img src={Founder} alt="Meet Founder" />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default MeetFounder;