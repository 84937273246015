import React, { useEffect } from "react";
import WebFont from 'webfontloader';
import AboutUs from "../../components/AboutUs";
import ContactUs from "../../components/ContactUs";
import FirstSection from "../../components/FirstSection";
import MeetFounder from "../../components/MeetFounder";
import Navbar from "../../components/Navbar";
import OurService from "../../components/OurService";
import Solution from "../../components/Solution";
import WorkWithUs from "../../components/WorkWithUs";
import './style.scss';

const HomePage = () => {
  useEffect(() => {
    document.title = "LawXTech"
    WebFont.load({
      google: {
        families: ['Manifestor:300,400,700', 'sans-serif']
      }
   });
  }, []);

  return (
    <div className="homepage">
      <div className="container">
        <Navbar />
        <FirstSection />
        <AboutUs />
        <MeetFounder />
        <OurService />
        <WorkWithUs />
        <Solution />
        <ContactUs />
      </div>
    </div>
  )
}

export default HomePage;