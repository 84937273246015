import React from "react";
import { Col, Row } from 'antd';
import Ondemand from './images/On-Demand.png';
import OutsourceChief from './images/Outsourced-Chief.png';
import PDPA from './images/PDPA.png';
import VirtualCompany from './images/Virtual-Company .png';

import './style.scss';

const OurService = () => {
  return (
    <div className="ourservice" id="service">
      <h2 className="text-green text-bold">Our Service</h2>
        <Row className="ourservice-container column-reverse">
          <Col xl={12} lg={12} md={12} xs={24} className="ourservice-content padding-right margin-bottom">
            <h3 className="text-green">On-Demand Legal Advisor</h3>
            <p>Let us find you the right legal solution to all of your problems, including license application. </p>
            <p>Find basic information from our Chatbot and Let’s Discuss further.</p>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24} className="margin-bottom">
            <div className="full-image">
              <img src={Ondemand} alt="On-Demand Legal Advisor" />
            </div>
          </Col>
        </Row>
        <Row className="ourservice-container">
          <Col xl={12} lg={12} md={12} xs={24} className="margin-bottom">
            <div className="full-image">
              <img src={OutsourceChief} alt="Outsourced Chief Legal Officer" />
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24} className="ourservice-content padding-left">
            <h3 className="text-green">Outsourced Chief Legal Officer</h3>
            <p>Let us be your affordable outsourced legal team to give you legal advice and help review all the document through your startup journey from product design to scale.</p>
          </Col>
        </Row>
        <Row className="ourservice-container column-reverse">
          <Col xl={12} lg={12} md={12} xs={24} className="ourservice-content padding-right">
            <h3 className="text-green">PDPA & Privacy by Design Consult</h3>
            <p>Let us help you be in full compliance with PDPA while maximizing your data utilization</p>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24} className="margin-bottom">
            <div className="full-image">
              <img src={PDPA} alt="PDPA & Privacy by Design Consult" />
            </div>
          </Col>
        </Row>
        <Row className="ourservice-container">
          <Col xl={12} lg={12} md={12} xs={24} className="margin-bottom">
            <div className="full-image">
              <img src={VirtualCompany} alt="Virtual Company Secretary" />
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24} className="ourservice-content padding-left">
            <h3 className="text-green">Virtual Company Secretary</h3>
            <p>Let us help you Mastering the Due Diligence with our company secretary services, ranging from meeting handling, share register to annual government filings. </p>
          </Col>
        </Row>
    </div>
  )
}

 export default OurService;