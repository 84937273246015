import React, { useEffect, useState } from "react";
import Logo from './images/logo.svg';
import { MenuOutlined } from '@ant-design/icons';
import { Link, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import LinkedIn from './images/linkedin.png';
import Facebook from './images/facebook.png';
import { Menu } from "antd";
import './style.scss';

const MenuList = [
  {
    key: "home",
    title: "Home",
    url: ""
  },
  {
    key: "about",
    title: "About",
    url: ""
  },
  {
    key: "service",
    title: "Our Service",
    url: ""
  },
  {
    key: "solution",
    title: "Solution",
    url: ""
  },
  {
    key: "contact",
    title: "Contact",
    url: ""
  }
];

function getItem(label, key) {
  return {
    key,
  };
}

const items = [
  getItem('Option 1', '1'),
  getItem('Option 2', '2'),
  getItem('Option 3', '3'),
]

const NavbarMobile =() => {
  const [open, setOpen] = useState(false);
  const handleOpenMenu = () => {
    setOpen(!open);
  }
  const scroll = (content) => {
    const section = document.querySelector(`#${content}`);
    section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    setOpen(!open);
  };
  
  return (
    <div className="navbar-mobile">
      <div className="navbar-logo"><img src={Logo} alt="LawXTech" /></div>
      <div>
        <div className="menu" onClick={handleOpenMenu}><MenuOutlined /></div>
        <Menu className={`navbar-item ${open ? 'open' : ''}`} mode="vertical" defaultSelectedKeys={["home"]}>
          {MenuList?.map((item) => {
            return (
              <Menu.Item onClick={() => scroll(item.key)} key={item.title}>{item.title}</Menu.Item>
            )
          })}
        </Menu>
      </div>
    </div>
  )
}

const Navbar = () => {
  const [active, setActive] = useState('');
  
  useEffect(() => {
    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    }
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-pc">
        <div><img src={Logo} alt="LawXTech" /></div>
        <ul className="navbar-list">
          {MenuList?.map((item, index) => {
            return (
              <li>
                <Link activeClass="active" offset={-90} to={item.key} spy={true} smooth={true} duration={500} onSetActive={() => setActive(item.key)}>
                  {item.title}
                </Link>
              </li>
              
            )
          })}
          <li>
            <a className="navbar-link"><img src={LinkedIn} alt="linkedIn" /></a>
            <a className="navbar-link"><img src={Facebook} alt="facebook" /></a>
          </li>
        </ul>
      </div>
      <NavbarMobile />
    </div>
  )
}

export default Navbar;